import { Component, Input, OnInit, computed, inject } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../core/services/auth/auth.service';
import { PermissionService } from '../permission.service';
import { HttpClient } from '@angular/common/http';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { ModuleMeta } from '../interface/interface-list';
import { DataSharingService } from '../product/dataSharing/data-sharing.service';

@Component({
  standalone: true,
  selector: 'app-sidebar',
  imports: [NgFor,  NgIf, SidebarItemComponent],
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  permittedModules: ModuleMeta[] = [];

  constructor( private http: HttpClient, private router: Router, public auth: AuthService,
    private permissionService: PermissionService,
    private data:DataSharingService
  ) {}
  ngOnInit(): void {
    // setTimeout(() => {
    this.data.setOperator$.subscribe((res)=>{
      if (res) {
        this.permittedModules = this.permissionService.getPermittedModules();
        console.log(this.permittedModules, "getPermittedModules");
      }
    })
    this.data.emptyArray$.subscribe((res:any)=>{
      if(res === true){
        this.permittedModules = []
      }
    })
    // }, 8000);
  }
  currentUser = inject(AuthService).currentUser
  // permittedModules = inject(PermissionService).permittedModels
  // filteredModuleNames = computed(()=>this.permittedModules().map(m=>m.name))
  openModule :string=""
  openModules: string[] = [];
  subComponents : any[] = [];
  private jsonUrl = 'assets/data-responses/response.json';


  toggleAccordion(moduleName: string): void {
    if (this.openModules.includes(moduleName)) {
      this.openModules = this.openModules.filter(name => name !== moduleName);
      // this.permittedModules().map( module =>{
      //   if(module.components?.length == 0){
      //     this.router.navigate(['/UserManagement']);
      //   }
      // })
      
      
    } else {
      this.openModules.push(moduleName);
      }
    
  }

  isAccordionOpen(moduleName: string): boolean {
   /*  if(moduleName == 'paymentAndMoneyTransfer'){
      this.getSubComponents(moduleName);
    } */
    return this.openModules.includes(moduleName);
  }


  onClickModule(moduleName: string): void {
    // this.openModule = moduleName
    // console.log("Clcked", moduleName)
    // if (moduleName === 'kyc') {
    //   const kycModule = new KycModule();
    //   const services = kycModule.getServices();
    //   this.filteredCapabilities = services
    //   console.log(services); // You can display or use the services as needed
    //   return
    // }
    // if (moduleName === 'Dashboard') {
    //   import('../dashboard/dashboard.module').then(module => {
    //     // Instantiate the module
    //     const dashboardModule = new module.DashboardModule();

    //     // Call the getServices() method
    //     const services = dashboardModule.getServices();

    //     // Now you can use the services array
    //     console.log(services);
    //     this.filteredCapabilities = services
    //     console.log(services); //
    //   });

    //   return
    // }
    // if (moduleName === 'Service Mangement') {
    //   const m = new ServiceManagementModule();
    //   const services = m.getServices();
    //   this.filteredCapabilities = services
    //   console.log(services); // You can display or use the services as needed
    //   return
    // }
    // if (moduleName === 'Profile Mangement') {
    //   const m = new ProfileManagementModule();
    //   const services = m.getServices();
    //   this.filteredCapabilities = services
    //   console.log(services); // You can display or use the services as needed
    //   return
    // }
  }

  getSubComponents(moduleName: string) : void {
    if(moduleName == 'paymentAndMoneyTransfer'){
     this.http.get<any>(this.jsonUrl).subscribe( data => {
      this.subComponents = data.paymentMoneyTransfer_subComponents;
      console.log("Sub Components", this.subComponents);
      })
 

    }
   
  
  }

  gotoSubComponent(id : string){
  }

}


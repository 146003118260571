import { HttpInterceptorFn } from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  return next(getJwtToken() != null && getJwtToken() != 'undefined' ? req.clone({
    setHeaders: { Authorization: `Bearer ${getJwtToken()}` }
  }) : req);
};


function getJwtToken(): string | null {
  return localStorage.getItem("JWT_TOKEN")
}
<div style="height: calc(100vh - var(--header-footer-height)); margin-top: 24px;" [class]="auth.getToken() != undefined && auth.getToken() != null?
    'overflow-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-[rgb(0,100,162)] scrollbar-track-white relative max-w-sm mx-auto bg-white dark:bg-slate-800 dark:highlight-white/5 shadow-lg ring-1 ring-black/5  flex flex-col divide-y dark:divide-slate-200/5 ml-2 pl-2 '
    :'hidden'">
  
  <div [hidden]="auth.currentUser() == ''" class="px-3">
    <app-sidebar-item
    *ngFor="let item of permittedModules"
    [module]="item">
  </app-sidebar-item>  
  </div>
</div>
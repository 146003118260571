<div class="bg-[#f4ad6e] shadow-md gap-2">
  <div
    class="flex items-center justify-between w-full px-4 py-2 bg-[#ffb677] text-gray-900 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
    (click)="isOpen = !isOpen"
  >
    <img [src]="module.icon" alt="Icon" class="w-6 h-6 mr-2" />
    <span class="flex-1 font-semibold">{{ module.name }}</span>
    <svg
      class="transition-transform duration-300 ease-in-out w-4 h-4"
      [class.rotate-180]="isOpen"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path fill-rule="evenodd" d="M10 12l-4-4h8l-4 4z" clip-rule="evenodd" />
    </svg>
  </div>
  <div
    *ngIf="module.components?.length != 0"
    [class.accordion-open]="isOpen"
    class="accordion-content bg-orange-50"
  >
    <div *ngFor="let feature of module.components">
      <a
        [routerLink]="feature.name"
        (click)="getRouteLink()"
        (click)="getComponentName(feature?.name)"
        routerLinkActive="font-bold"
        class="block py-2 text-gray-600 ml-4 hover:text-blue-500 hover:underline"
        >{{ feature.displayLink }}</a
      >
    </div>
  </div>
</div>
